@use '../../styles' as *;

/* Clean Rsuite CSS */
p + p {
	margin-top: 0;
}
.rs-picker-daterange-menu {
	height: 315px;
	padding: 1rem;

	.rs-picker-daterange-predefined {
		border: none;
	}
}
.rs-picker-daterange-header {
	display: none;
}
.rs-stack {
	&-item {
		display: flex;
	}

	.rs-picker-toolbar {
		flex-direction: column;
		border: none;

		&-ranges {
			flex-direction: column;
		}
	}
}
.rs-table {
	box-shadow: $shadow-light;
}

/* Dashboard CSS */
.stats-filter {
	position: absolute;
	top: 3rem;
	right: 3rem;

	& > button {
		all: unset;
		margin-left: 1rem;
		padding: 0.5rem 1rem;
		font-weight: 500;
		color: #fff;
		background: $primary;
		border-radius: 3px;
		cursor: pointer;
	}
}

.stats-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 3rem;
	padding: 1rem 0;

	.stats-item {
		width: 30%;
		height: 225px;
		padding: 2rem;
		box-shadow: $shadow-light;
		border-radius: 5px;

		& > span {
			display: block;
			padding-top: 2rem;
			color: #fff;
		}
		
		.stats-wrapper {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 3rem;

			p {
				display: flex;
				align-items: center;
				gap: 10px;
				font-size: $text-xl;
				color: #fff;
				
				& > svg {
					cursor: pointer;
				}
			}
			& > svg {
				width: 25px;
				height: 25px;
				color: #fff;
			}

			.dialog-box {
				position: absolute;
				top: 0.5rem;
				left: 9rem;
				padding: 0.5rem;
				border-radius: 5px;
				background-color: #fff;
			}
		}

		& > p {
			font-size: $text-xxl;
			color: #fff;
			font-weight: 500;
		}
		
		&.primary {
			background: $circles, $primary-gradient;
			background-repeat: no-repeat;
			background-position: right;
		}
		&.success {
			background: $circles, $success-gradient;
			background-repeat: no-repeat;
			background-position: right;
		}
		&.danger {
			background: $circles, $danger-gradient;
			background-repeat: no-repeat;
			background-position: right;
		}
	}
}

.month-results {
	margin-top: 48px;
}

.chart-container {
	display: flex;
}
.chart-wrapper {
	max-width: 48%;
	flex-grow: 1;
	margin: 3rem auto;
	padding: 2rem 2rem 1rem 2rem;
	box-shadow: $shadow-light;
	border-radius: 5px;
	background: #fff;
}

.Campaign {
	&-activable {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
		border-radius: 5px;
		background: $circles, $success-gradient;
		background-repeat: no-repeat;
		background-position: right;
		cursor: pointer;
		
		&.open {
			border-radius: 5px 5px 0px 0px;
		}

		&-item {
			flex-grow: 1;
			font-size: 1.1rem;
			color: #fff;
			display: flex;
			flex-direction: column;
			gap: 8px;

			&:first-child {
				flex-grow: 3.5;
			}
			
			& > span {
				display: flex;
				align-items: center;
				gap: 8px;

				&:last-child {
					font-weight: 500;
				}
			}

			& > svg {
				width: 25px;
				height: 25px;
			}
		}
	}
}
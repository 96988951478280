/* ===== Fonts ===== */
$font-fam-Ubuntu: 'Ubuntu', sans-serif;

$text-primary: #343A40;
$text-secondary: #76838F;
$placeholder: #A2A3A8;

$h1: 2.188rem;
$h2: 1.875rem;
$h3: 1.563rem;
$text-sm: 0.875rem;
$text-md: 1rem;
$text-lg: 1.125rem;
$text-xl: 1.25rem;
$text-xxl: 2rem;

/* ===== Colors ===== */
$primary: #008CFF;
$secondary: #C3BDBD;
$success: #1BCFB4;
$danger: #FE7C96;
$warning: #FED713;
$info: #B66DFF;

$primary-gradient: linear-gradient(90deg, #55aff8, $primary);
$success-gradient: linear-gradient(90deg, #5cdacf, $success);
$danger-gradient: linear-gradient(90deg, #FFBF96, $danger);

$circles: url('/../public/img/circles.svg');

/* ===== Shadows ===== */
$shadow-light: 0 0 6px rgba(0,0,0,0.1);

/* ===== Breakpoints ===== */
$breakpoint-sm: 767px;
$breakpoint-lg: 992px;
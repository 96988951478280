@use '../../styles' as *;

.navigation {
	display: flex;
	gap: 2rem;

	.nav-tab {
		position: relative;
		padding-bottom: 5px;
		cursor: pointer;
	}

	.nav-tab.active::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: $primary;
	}
}

.infos-form,
.address-form {
	display: none;
	padding-top: 1rem;

	&-section {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		margin-top: 1rem;

		& > p {
			width: 100%;
			padding-top: 2rem;
			font-size: $text-lg;
			font-weight: 500;
		}

		& > div {
			display: flex;
			flex-direction: column;
			width: 100%;

			label {
				padding: 0.5rem 0;
			}

			p {
				justify-self: self-end;
				padding: 1rem 0;
				color: red;
			}
		}

		& input {
			all: unset;
			width: 30%;
			padding: 1rem;
			font-size: $text-md;
			border: 1px solid rgba($color: $primary, $alpha: 0);
			border-radius: 3px;
			background: #fff;
		}
		& input:focus {
			border: 1px solid rgba($color: $primary, $alpha: 0.75);
		}

		& > button {
			all: unset;
			margin-top: 3rem;
			padding: 1rem;
			font-weight: 500;
			color: #fff;
			border-radius: 3px;
			background: $primary;
			cursor: pointer;
		}
	}

	&-errors {
		display: flex;
		& > p {
			flex-grow: 1;
			flex-basis: 100%;
			padding-left: 1rem;
			font-weight: 500;
			color: red;
		}
	}
}

.address-form {
	display: none;
}

.infos-form.active,
.address-form.active {
	display: block;
}
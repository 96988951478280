@use '../../styles' as *;

.Main {
	position: relative;
	padding: 3rem;

	& > p {
		margin-bottom: 2rem;
		font-size: $text-lg;
		font-weight: 500;
	}

	& > .Searchbar {
		position: absolute;
		top: 3rem;
		right: 7rem;
	}
}
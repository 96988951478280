@use '../../styles' as *;

.Home {
	display: flex;

	&-right-section {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
}
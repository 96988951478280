@use './styles' as *;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

a {
	all: unset;
	color: $primary;
	&:hover {
		text-decoration: underline $primary;
		cursor: pointer;
	}
}

body {
	height: 100vh;
	background: #EBEDF2;
	font-family: $font-fam-Ubuntu;
	color: $text-primary;
}
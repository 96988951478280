@use '../../styles' as *;

.NotFound {
	@extend %flex-center;
	gap: 1rem;
	height: 100vh;

	h1 {
		font-size: 5rem;
	}

	p {
		font-size: 1.2rem;
	}
}
@use '../../styles' as *;

.UserInfos {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	padding: 2rem 0;

	.user-photo {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		background-color: #a0a0a0;

		& > img {
			width: 50px;
			height: 50px;
			border-radius: 50px;
		}
	}
	.user-name {
		font-weight: 600;
	}
	.user-company {
		font-size: $text-sm;
		color: $text-secondary;
	}
}
@use '../../styles' as *;

.SignIn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	@extend %flex-center;
	align-items: unset;
	gap: 2rem;

	max-width: 450px;
	padding: 3rem;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0px 0px 6px rgba(0,0,0,0.1);

	img {
		width: 143px;
		margin: auto;
	}

	&-title {
		@extend %flex-center;

		h1 {
			font-size: $text-xl;
		}
		p {
			color: $text-secondary;
		}
	}

	&-form {
		@extend %flex-center;
		gap: 1rem;

		.input-text {
			all: unset;
			width: 100%;
			padding: 1rem 0;
			border-bottom: 1px solid #dbdde0;
			border-radius: 2px;
		}
		.input-text::placeholder {
			color: $placeholder;
		}
		.input-text:focus {
			border-bottom: 1px solid rgba($color: $primary, $alpha: 0.75);
		}

		.input-submit {
			all: unset;
			width: 100%;
			margin-top: 1rem;
			padding: 1rem 0;
			text-align: center;
			font-weight: 500;
			color: #fff;
			background: $primary-gradient;
			border-radius: 3px;
			cursor: pointer;
		}

		.error-msg {
			font-size: $text-sm;
			font-weight: 500;
			color: red;
		}
	}

	p {
		margin: auto;
	}
}
@use '../../styles' as *;

.Menu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 70px;
	background: #fff;
	
	.controls {
		display: flex;
		align-items: center;
		gap: 3rem;
		height: 100%;
		margin-right: 3rem;

		button {
			all: unset;
			width: 50px;
			height: 50px;
			cursor: pointer;
		}

		.icon {
			display: block;
			width: 25px;
			height: 25px;
			margin: auto;
			color: $primary;
		}
	}
}
@use '../../styles' as *;

.Navbar {
	min-width: 260px;
	min-height: 100vh;
	background: #fff;

	&-logo {
		display: flex;
		justify-content: center;
		padding: 2rem 0;
	}

	&-links {
		.link {
			display: flex;
			justify-content: space-between;
			padding: 1rem 2rem;
		}
		.link:hover {
			cursor: pointer;
			background-color: rgba($color: $primary, $alpha: 0.15);
		}

		.icon {
			width: 20px;
			height: 20px;
			color: $primary;
		}
	}
}

